import React from 'react';
import './contact.css'

export default function Home() {
    
  return (
    <div className='main-div'>
        <div>
            <h1>My professional contact:</h1>
            <h2><a href="mailto:jrixweb@gmail.com">jrixweb@gmail.com</a></h2>
        </div>
    </div>
  )
}
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import lowiin from '../img/lowiinproject.png';
import ianumber from '../img/ianumber.png';
import gravitysys from '../img/gravitysys.png';
import keys from '../img/keys.png';
import lemmings from '../img/lemmings.png';
import snakegame from '../img/snakegame.png';
import arma3 from '../img/arma3.png';
import rustserver from '../img/rustserver.png';
import spymaster from '../img/spymaster.png';
import auto from '../img/auto.png';
import restaurants from '../img/restaurants.png';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        paginationClickable = {true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 10,
          stretch: 50,
          depth: 300,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
        style={{"--swiper-pagination-color": "#6cb9dd",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "0.5",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px"}}
      >
        <SwiperSlide>
          <img src={restaurants} />
          <p>DIFTIP - Management of restaurant tips and google reviews</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={lowiin} />
          <p>A responsive website to buy inexpensive game keys</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={spymaster} />
          <p>The SpyMaster. Fantasy Romance novel</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={ianumber} />
          <p>An AI capable of recognising any drawn shape</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={gravitysys} />
          <p>A complex solar system with PyGame using the laws of physics</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={keys} />
          <p>Method for encrypting complex binary coded messages in Python</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={lemmings} />
          <p>Lemming game in Python</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={auto} />
          <p>Autobiography - Coming out at the end of 2024</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={snakegame} />
          <p>Snake Game using only Python arrays</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={arma3} />
          <p>I set up a team, created an Arma3 server and welcomed a community</p>
        </SwiperSlide>
        <SwiperSlide>
          <img src={rustserver} />
          <p>Set up a Rust server with custom mods and scripts for a better experience</p>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

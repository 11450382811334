import React, { useEffect, useState, useRef } from 'react';
import '../styles/home.css';
import Character from '../animation/character';
import JavaScript from '../svg/logo-javascript.svg';
import Php from '../svg/php.svg';
import Python from '../svg/python.svg';
import C from '../svg/c.svg';
import Reactsvg from '../svg/react.svg';
import Html5 from '../svg/html.svg';
import CSS from '../svg/css.svg';
import VUE from '../svg/vue.svg';
import Backimage from '../svg/2062024.svg';
import Swipe from '../slide/slides'
import Fade from 'react-reveal/Fade';

export default function Home() {

    useEffect(() => {
        const menu = document.getElementById('mobile-toggle');
        const form = document.getElementById('form-inline-responsive');
    
        const toggleForm = () => {
            const style = getComputedStyle(form);
            if (style.display === 'none') {
                form.classList.add('fade-in');
                form.classList.remove('fade-out');
                form.style.display = "block";
            } else {
                form.classList.add('fade-out');
                form.classList.remove('fade-in');
                form.style.display = "none";
            }
        };
        
        menu.addEventListener('click', toggleForm);
        // Cleanup the event listener on component unmount
        return () => {
            menu.removeEventListener('click', toggleForm);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const parallaxElement = document.querySelector('.ImageBackground'); // Sélectionnez l'élément ImageBackground

            if (parallaxElement) {
                const parallaxOffset = scrollPosition * 0.1;
                parallaxElement.style.transform = `translateY(${parallaxOffset}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <div>
            <section class="navbar-sec">
                <nav class="navbar navbar-light justify-content-between" id="navbar">
                    <h1 class="navbar-brand">Jason Mourier</h1>
                    <div class="menu-toggle" id="mobile-toggle">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                    <form class="form-inline" id='form-inline'>
                        <h1><a href='#skills'>Skills</a></h1>
                        <h1><a href='#projects'>Projects</a></h1>
                        <h1><a href='/contact'>Contact me</a></h1>
                    </form>
                </nav>
                <div class="second-form-reponsive">
                    <form class="form-inline-responsive" id='form-inline-responsive'>
                        <h1><a href='#skills'>Skills</a></h1>
                        <h1><a href='#projects'>Projects</a></h1>
                        <h1><a href='/contact'>Contact me</a></h1>
                    </form>
                </div>
            </section>
            <section class="presentation-sec">
                <div class="div-presentation" id='div-presentation'>
                    <h1>Hi, there!</h1>
                    <h1>I am</h1>
                    <h1 class="h1-jason-title">Jason Mourier</h1>
                    <p>A future software engineer working hard to achieve his dreams</p>
                </div>
                <div class="svg-animation">
                    <Character id="Ca"/>
                </div>
            </section>
            <section class='skills-sec' id='skills'>
                <img src={Backimage} alt='Imagebackground' class='ImageBackground'/>
                <div className='skills-text'>
                    <h1>Skills & Experience</h1>
                    <p>My areas of expertise are engineering, software development, history, and writing.</p>
                    <p>Python, JS, PHP, C#, React, and others are helping me build small projects to explore my capabilities in science and development. I'm always thinking about creating something bigger.</p>
                    <p>Visit my <a href='https://github.com/Jrix-G' class='hyperlinkgithub'>Github</a> for more details</p>
                    <div className='skills-div-logos'>
                        <Fade cascade delay={200}>
                            <div>
                                <img src={JavaScript} alt="Logo Javascript" class='imageStyle'/>
                                <p class='skills-p'>JavaScript</p>
                            </div>
                        </Fade>
                        <Fade delay={250}>
                            <div>
                                <img src={Php} alt="Logo Javascript" class='imageStyle'/>
                                <p>PHP</p>
                                
                            </div>
                        </Fade>
                        <Fade delay={300}>
                            <div>
                                <img src={Python} alt="Logo Javascript" class='imageStyle'/>
                                <p>Python</p>
                            </div>
                        </Fade>
                        <Fade delay={350}>
                            <div>
                                <img src={C} alt="Logo Javascript" class='imageStyle'/>
                                <p>C#</p>
                            </div>
                        </Fade>
                        <Fade delay={400}>
                            <div>
                                <img src={Reactsvg} alt="Logo Javascript" class='imageStyle'/>
                                <p>React</p>
                            </div>
                        </Fade>
                        <Fade delay={450}>
                            <div>
                                <img src={VUE} alt="Logo Javascript" class='imageStyle'/>
                                <p>Vue</p>
                            </div>
                        </Fade>
                        <Fade delay={500}>
                            <div>
                                <img src={CSS} alt="Logo Javascript" class='imageStyle'/>
                                <p>CSS3</p>
                            </div>
                        </Fade>
                        <Fade delay={550}>
                            <div>
                                <img src={Html5} alt="Logo Javascript" class='imageStyle'/>
                                <p>HTML5</p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <section className='projects-sec' id='projects'>
                <div class='projects-div-div'>
                    <div class='projects-div-text'>
                        <h1>Portfolio & Previous Projects</h1>
                        <p>I have built various types of projects, such as websites, applications, and software. Additionally, I have written various types of books, including novels and philosophical works.</p>
                    </div>
                    <div>
                        <Swipe />
                    </div>
                </div>
            </section>
            <div className='jason-mourier-copyrights'>
                <h1 className='jason-mourier-copyrights-h1'>© Jason Mourier - 07/2024</h1>
            </div>
        </div>
    )
}